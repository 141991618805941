// TripperBus - _navbar.scss

.sticky{
	position: sticky;
	top: 0;
	z-index: 100;
}

#main-nav{
	@media screen and (max-width:1199px) {
		grid-column: 1/4;
	}
}

.navbar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top: 0;
	height: 60px;
	border-bottom: 1px solid rgba(255, 255, 255, .4);
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0);
	padding: 0;
	margin-top: 0;
	transition: .3s background-color ease-in-out, .3s padding ease-in-out, .3s height ease-in-out, .3s border ease-in-out, .3s box-shadow ease-in-out;
  	background-color: #1B1E21;


.container{

	.navbar-toggle-bar{
		display: none;
	}

	@media screen and (max-width:1199px) {
	 max-width: 1200px;
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	width: 100%;
	padding: 5px 20px;
	box-shadow: 0 4px 4px 0 rgba(0,0,0,.5);

	#menu-item-78{
		justify-self: end;
	}

	#menu-item-78.dashboard{
		width: 100%;
	}

	.navbar-toggle-bar{
		display: inline;
	}

	.login-nav-link{
		display: block;
		border-radius: 2px;
	padding: 5px 6px;
    height: 26px;
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #4E5864;
    line-height: normal;
    background: none;
    transition: none;
	}


}}



	// @media(max-width:1200px) {
	// 	background-color: transparentize($tripper6, 0);
	// 	border-bottom: 1px solid transparentize($gray-600, 1);
	// 	box-shadow: 0 1px 4px transparentize($black, .75);
	// }

	button {
		i {
			color: $white;
			font-size: 20px;
		}
  }

  .dropdown {
    position: relative;

    .dropdown-menu {
      -webkit-transition: all .3s;
      transition: all .3s;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 0;
      width: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      opacity: 0;
      padding: 0;

      li {
      	margin: 0;
      	padding: 0;
      	border-bottom: 1px solid #3b4956;

      	a,
      	a:visited {
      		color: $white;
      		font-size: .84rem;
      		margin: 10px 0;
      		font-weight: normal;
      		text-transform: uppercase;
      		letter-spacing: .05rem;
      		padding: 0;
      	}

      	a:focus,
      	a:hover {
      		color: $tripper2;
      		background-color: transparent;
      	}
      }
    }

    >.dropdown-menu {
      margin: 0;
      background-color: rgba(41, 51, 60, 1);
      border: 0 none;
      border-radius: 0;
      color: $white;
      border-radius: 0 0 3px 3px;
      width: 0;
      min-width: 200px;
    }

    &:hover {
      .dropdown-menu {
        opacity: 1;
        height: auto;
        width: auto;
        padding: 15px;
      }
    }
  }

	&.navbar-change {
		// background-color: transparentize($tripper6, 0);
		// border-bottom: 1px solid transparentize($gray-600, 1);
		// box-shadow: 0 1px 4px transparentize($black, .75);

		.navbar-brand {
			width: 100px;

			svg {
				.st0 {
					fill: $tripper2;
				}
			}
		}
	}

	.navbar-brand {
		max-width: 100%;
		height: auto;
		width: 127px;
		box-sizing: border-box;
		transition: .3s width ease-in-out;
		margin-right: 1rem;

		svg {
			.st0 {
				fill: $tripper2;
				transition: .3s fill ease-in-out;
      }

      &:hover {
				.st0 {
					fill: $white;
				}
      }
		}
	}

	#secondary-nav {
		ul {
			li {
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	#menu-main-menu {
		display: none;
			
		li {
			a {
				padding: 20px 0;
				@media (max-width:1199px) {
					padding: 10px 0;
					color: black;
				}

      }
      &.dropdown {
        .dropdown-menu {
          a {
            padding: 0;
          }
        }
      }
		}


		@media screen and (max-width:1199px) {
			&.drawer {display: flex;
			position: fixed;
			width: 100%;
			z-index: 10;
			background: white;
			color: black;
			height: calc(100vh - 60px);			
			top: 60px;
			left: 0;
			overflow: hidden;
			justify-content: space-between;
			animation: openPopup 0.3s ease-out forwards;

		}

			@keyframes openPopup {
				0% {
				  left: -1000%;  
				}
				50% {
				  left: -50%;  
				}
				100% {
				 left: 0%;
			
				}
			  }

			  &.drawer-close{
				animation: closePopup 0.3s ease-in backwards;
			  }

			  @keyframes closePopup {
				0% {
					display: flex;
					position: fixed;
					width: 100%;
					z-index: 10;
					background: white;
					color: black;
					height: calc(100vh - 69px);			
					top: 68px;
					left: 0;
					justify-content: space-between;
					width: 100%;

				}
				50% {
					display: flex;
					position: fixed;
					width: 100%;
					z-index: 10;
					background: white;
					color: black;
					height: calc(100vh - 69px);			
					top: 68px;
					justify-content: space-between;
					width: 100%;

				  	left: -50%;}
				75% {
						display: flex;
						position: fixed;
						width: 100%;
						z-index: 10;
						background: white;
						color: black;
						height: calc(100vh - 69px);			
						top: 68px;
						justify-content: space-between;
						width: 100%;
	
						left: -75%;}
				100% {
					display: flex;
					position: fixed;
					width: 100%;
					z-index: 10;
					background: white;
					color: black;
					height: calc(100vh - 69px);			
					top: 68px;
					width: 100%;
					justify-content: space-between;
				  	left: -100%;
				}
			  }

			& li .navlink a{
				color: #000;
			}

			.rewardDetails{
				background: #f9fafb;
				padding: 25px;
				display: grid;
				grid-template-columns: auto 1fr;
				justify-items: center;
				align-items: start;
				column-gap: 1rem;

				div .head{
					color: #000;
    				font-size: 20px !important;
    				font-style: normal;
    				font-weight: 700;
    				line-height: normal;
				}

				div a{
					color: $tripper2;
				}

				div span{
					font-size: 14px;
					max-width: 14rem;
					display: block;
				}
			}
}

@media screen and (min-width: 567px) {
	.rewardDetails{
		
		div span{
			font-size: 14px;
			max-width: 100%;
			display: block;
		}
	}
}


}

	ul {
		.innerContent{
			margin-top: 1.5rem;
		}
		li {
			margin-top: 0;

			@media screen and (max-width:1199px) {
				padding: 3px 20px;
			}

			&.active {
				a {
					color: $tripper2;
					font-weight: normal;
				}
			}

	
			a {
				position: relative;
				font-size: .96rem;
				color: $white;
				margin: 0;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: .05rem;

				&:focus,
				&:hover,
				&.active {
					color: $tripper2;
					font-weight: normal;
				}

				@media (max-width:1199px) {
					padding: 10px 0;
				}
			}
			.FAQ {
				text-transform: none!important;
			}

			.loginBtn {
				border: 1px solid #fff;
				padding: 5px 25px;
				border-radius: 6px;
				display: block;
				color: $white;
				position: relative;
				font-size: .885rem;
				margin: 0;
				font-weight: 400;
				text-transform: uppercase;
				letter-spacing: .05rem;
				background: transparent;

				&:focus,
				&:hover,
				&.active {
					cursor: pointer;
					color: $tripper2;
					font-weight: normal;
					background-color: #fff;

				}

				@media (max-width:1199px) {
					padding: 10px 0;
				}
			}
		}
	}

	@media only screen and (min-width: 1025px) {
		#menu-main-menu {
			>li {
				>a {
					&::before {
						content: '';
						position: absolute;
						bottom: -6px;
						left: 0;
						width: 100%;
						height: 1px;
						background: var(--white);
						-webkit-transform: scale3d(0, 1, 1);
						-moz-transform: scale3d(0, 1, 1);
						-ms-transform: scale3d(0, 1, 1);
						-o-transform: scale3d(0, 1, 1);
						transform: scale3d(0, 1, 1);
						-webkit-transition: -webkit-transform .1s;
						-moz-transition: -moz-transform .1s;
						-ms-transition: -ms-transform .1s;
						-o-transition: -o-transform .1s;
						transition: transform .1s ease-in-out, width .1s ease-in-out;
					}
				}

				&:hover,
				&.active {
					>a {
						&::before {
							-webkit-transform: scale3d(1, 1, 1);
							transform: scale3d(1, 1, 1);
							-webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
							transition-timing-function: cubic-bezier(.4, 0, .2, 1);
							-webkit-transition-duration: .3s;
							transition-duration: .3s;
						}
					}
				}
			}
		}
	}
}

.notification-bar {
	color: var(--white);
	background-color:#434343;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0;
	font-size: 14px;
	margin: 0;
	letter-spacing: .05rem;
	padding: 0;
	min-height: 45px;

	i {
		color: #fff;
	}

	.container {
		position: relative;
		padding: 10px 120px 10px 15px;

		@media screen and (max-width: 420px) {
			padding: 10px 36px 10px 15px;
		}
	}

	button.close {
		margin-top: 8px;
		padding: 15px 10px;
		text-shadow: none;
		font-size: 14px;
		font-weight: normal;
		text-transform: uppercase;
		color: var(--white);
		text-align: center;
		opacity: 1;
		-webkit-transition: .3s background-color ease-in-out;
		transition: .3s background-color ease-in-out;
		margin-right: 15px;
		border-radius: 0 0 10px 10px;
	}
}


#desktop-nav{
		margin: auto;
}

#desktop-nav .innerContent{
	display: flex;
	margin-top: 0;
    // width: 28rem;
	ul{
		margin-bottom: 0;

		li{
			padding: 0
		}
	}

}

#secondary-nav{
	.nav-link{
		padding-right: 1rem;
	}
}


#menu-secondary-menu{
	margin: 0;
	padding: 0;
}