// TripperBus - _global.scss
html {
	font-size: 95%;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body {
	@include body-font;
}

a {
	color: $tripper2;
	transition: .3s background-color ease-in-out, .3s color ease-in-out;

	&:visited {
		color: $tripper-gray;
		transition: .3s background-color ease-in-out, .3s color ease-in-out;
	}

	&:focus,
	&:hover {
		color: $tripper-gray;
		text-decoration: none;
	}
}

.m-28{
	margin-left: 28px;
	display: block;
}

.phone a{
	color: white !important;
	text-decoration: underline;
}

.navbar-brand:hover svg path{
	fill: $tripper2 !important;
}

.affix {
	top: 20px;
	z-index: 9999 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include heading-font;
}

.app-container{
	min-height: calc(100vh - 300px);
}

// Section Padding Settings

.error {
	color: red;
}
.error > .coupon{
	text-decoration: underline;
}

.success{
  color: green;
}

.hide-mobile {
	@media(max-width:768px) {
		display: none !important;
	}
}

.mobile-only {
	display: none;

	@media(max-width:768px) {
		display: block !important;
	}
}

.special-offer{
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-bottom: 1rem;

	p{
		font-weight: bold;
		font-size: 1rem;

		@media screen and (max-width:1199px) {
			font-weight: 600;
			font-size: 13px;
		}
	}

	div:last-child{
		justify-self: end;
		span{
			color: $tripper2;
			cursor: pointer;
		}
	}
}

.disable-section{
	pointer-events: none;
	opacity: 0.5;
	cursor: not-allowed;
}

.bottom-purchase-section{
	background: #252F3A;
	width: 100%;
	height: 110px;
	margin-top: -2rem;
	border-radius: 6px;
	display: grid;
	border-top-left-radius: 0;
	justify-items: end;
	align-items: center;
	padding: 1rem;

	@media screen and (max-width: 1199px) {
		justify-items: center;
	}
}

.text-white{
	color: #fff;

	a{
		color: #fff !important;
		text-decoration: underline;
	}
}


.close-details {
	display: none;

	@media(max-width:768px) {
		display: block !important;
		margin-top: -15px;
		padding: 10px !important;
		margin-bottom: 10px;
		margin-left: 20px;
	}
}

.relative-form-group {
	position: relative;
	padding-bottom: 22px;
	margin-bottom: 5px;

	.error {
		bottom: 5px;
		color: red;
		font-size: 12px;
	}
}

section {
	padding: 6rem 0;

	h2 {
		font-size: 2.25rem;
		line-height: 2rem;
	}

	@media (min-width: 992px) {
		h2 {
			font-size: 3rem;
			line-height: 2.5rem;
		}
	}
}

// Custom Button Size

.btn-xl {
	padding: 1rem 1.75rem;
	font-size: 1.25rem;
}

.tripper-button{
	background-color: $tripper2;
	color: #fff;
	border-radius: 4px;
	padding: 8px 24px;
	border: none;
	cursor: pointer;
}

.tripper-button:disabled{
	background-color: $tripper-gray;
	opacity: 0.5;
}

.head-title, .order-summary .head-title span{
	color: $tripper2;
	font-size: 14px !important;
	font-weight: 600;
	padding-bottom: 1rem;
}

.customer-grid{
	display: grid;
}

.end {
	justify-self: end;

}

// loader

.fond {
	padding: 100px 0;
	width: 100%;
}

.no-trip-found {
	text-align: center;
	margin: 0 auto;

	img {
		height: 100px;
	}

	.no-trip-head {
		font-size: 20px;
		font-weight: bold;
	}

	.no-trip-msg {
		font-size: 16px;
	}
	.no-trip-msg > a{
		font-weight: bold;
	}
}

.editing-existing {
	background-color: #e8512f;
	left: 0;
	right: 0;
	color: #fff;
	padding: 5px 20px;


	i {
		margin-right: 10px;
	}
}

.server-error {
	padding: 10px 15px;
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
	margin-top: 10px;
}

.server-success {
	padding: 10px 15px;
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
}

.ReactPasswordStrength {
	border-radius: 5px;
}

.ReactPasswordStrength-input {
	border: none !important;
	font-size: 1rem !important;
	padding: .375rem .75rem !important;
}

.fa-times-circle {
	cursor: pointer;
}

.cursor-pointer {
	cursor: pointer;
}

.contener_general {
	-webkit-animation: animball_two 1s infinite;
	-moz-animation: animball_two 1s infinite;
	-ms-animation: animball_two 1s infinite;
	animation: animball_two 1s infinite;
	width: 44px;
	height: 44px;
	margin: 0 auto;
}

.contener_mixte {
	width: 44px;
	height: 44px;
	position: absolute;
}

.ballcolor {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.ball_1,
.ball_2,
.ball_3,
.ball_4 {
	position: absolute;
	-webkit-animation: animball_one 1s infinite ease;
	-moz-animation: animball_one 1s infinite ease;
	-ms-animation: animball_one 1s infinite ease;
	animation: animball_one 1s infinite ease;
}

.ball_1 {
	background-color: $tripper2;
	top: 0;
	left: 0;
}

.ball_2 {
	background-color: #ffb300;
	top: 0;
	left: 24px;
}

.ball_3 {
	background-color: #1f2123;
	top: 24px;
	left: 0;
}

.ball_4 {
	background-color: #f15543;
	top: 24px;
	left: 24px;
}

@-webkit-keyframes animball_one {
	0% {
		position: absolute;
	}

	50% {
		top: 12px;
		left: 12px;
		position: absolute;
		opacity: 0.5;
	}

	100% {
		position: absolute;
	}
}

@-moz-keyframes animball_one {
	0% {
		position: absolute;
	}

	50% {
		top: 12px;
		left: 12px;
		position: absolute;
		opacity: 0.5;
	}

	100% {
		position: absolute;
	}
}

@-ms-keyframes animball_one {
	0% {
		position: absolute;
	}

	50% {
		top: 12px;
		left: 12px;
		position: absolute;
		opacity: 0.5;
	}

	100% {
		position: absolute;
	}
}

@keyframes animball_one {
	0% {
		position: absolute;
	}

	50% {
		top: 12px;
		left: 12px;
		position: absolute;
		opacity: 0.5;
	}

	100% {
		position: absolute;
	}
}

@-webkit-keyframes animball_two {
	0% {
		-webkit-transform: rotate(0deg) scale(1);
	}

	50% {
		-webkit-transform: rotate(360deg) scale(1.3);
	}

	100% {
		-webkit-transform: rotate(720deg) scale(1);
	}
}

@-moz-keyframes animball_two {
	0% {
		-moz-transform: rotate(0deg) scale(1);
	}

	50% {
		-moz-transform: rotate(360deg) scale(1.3);
	}

	100% {
		-moz-transform: rotate(720deg) scale(1);
	}
}

@-ms-keyframes animball_two {
	0% {
		-ms-transform: rotate(0deg) scale(1);
	}

	50% {
		-ms-transform: rotate(360deg) scale(1.3);
	}

	100% {
		-ms-transform: rotate(720deg) scale(1);
	}
}

@keyframes animball_two {
	0% {
		transform: rotate(0deg) scale(1);
	}

	50% {
		transform: rotate(360deg) scale(1.3);
	}

	100% {
		transform: rotate(720deg) scale(1);
	}
}

.only-mobile {
	display: none !important;

	@media (max-width: 1200px) {
		display: block !important;
	}
}

.orange{
	color: #E8512F !important;
}

.only-mobile.grid {
	display: none !important;

	@media (max-width: 1200px) {
		display: grid !important;
	}

	&{
		grid-template-columns: 1fr 1fr;
	}
}



.only-desktop {
	display: block;

	@media (max-width: 1200px) {
		display: none !important;
	}
}

.no-stop-error {
	display: inline-block;
	color: red;
	margin-right: 10px;
}

//trip-filters
.trip-filters {

	.labelRD{
		font-weight: 700;
    	padding: 10px 2px;
	}

	.grid{
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 1rem;

		@media screen and (max-width:591px) {
			&{
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, 1fr);
			}

			& .date-trip{
				grid-column: 1/3;
			}
		}


		@media screen and (max-width:426px) {
			&{
				grid-template-columns: repeat(1, 1fr);
				grid-template-rows: repeat(3, 1fr);
			}
			.departingCity{
				grid-column: 1/3;
			}
			.arrivingCity{
				grid-column: 1/3;
			}
			& .date-trip{
				grid-column: 1/3;
			}
		}
	}

	.departingCity {
		padding-right: 0;

			// &::after {
			// 	background: url(../img/between_arrow.svg) no-repeat right center;
			// 	position: absolute;
			// 	width: 34px;
			// 	height: 30px;
			// 	content: '';
			// 	right: -16px;
			// 	top: 50%;
			// 	transform: translateY(-50%);

			// 	@media(max-width:767px) {
			// 		background-size: 16px auto;
			// 		width: 16px;
			// 		right: -8px;
			// 	}
			// }
		}

}


// Travel Form Region

#travel-form-region {
  background-color: $dark;
	margin-top: 0;
	padding-bottom: 0;
	padding: 0;

  .tripType{
    height: 10px;
    margin-right: 6px;
  }

  .roundtrip{
    margin-left: 5px;
    select{
      border: none;
      background-position: 6.5rem center;
    }
  }

	.travel-form-wrapper {
		background-color: transparent;
		position: relative;
		z-index: 5;
		margin: 0 auto;
		border: 0 none;
		width: 100%;

		input {

			&:focus,
			&:hover {
				outline: none !important;
				box-shadow: none;
			}
		}
	}

	.travel-form--label {
		color: #495057;
		text-transform: uppercase;
		letter-spacing: .05rem;
		font-size: 12px;
		font-weight: 300;
		padding: 15px 0 5px;
		position: absolute;
	    top: -3px;
	    left: 15px;
	    right: 0;
	    span {
	    	position: absolute;
    		top: -15px;
	    }
	}

  .marginTop{
    margin-top: -18px;
  }

  .visibilityHidden{
    visibility: hidden;
  }

  #travel-form-region .travel-form--label span{
    position: absolute;
    top: -15px;
  }

	.react-datepicker__input-container,
	.react-datepicker-wrapper {
		display: block;
	}

	.content-full-width {
		.tab-content {
      color: $white;
			clear: left;
			padding: 30px 25px 50px;
			max-width: 100%;
			width: 100%;
			box-sizing: border-box;
			border-radius: 0 0 8px 8px;

      select, input{
        background-color: $dark;
      }

			@media (max-width: 1024px) {
				padding: 10px 0px 35px
			}

			&.reduced-shadow {
				padding-top: 50px;
			}
		}
	}

	.tabs-wrapper {
		border-radius: 5px 5px 0 0;
		background-color: transparentize($tripper4, .05);
		height: 150px;
		padding: 0 0 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		.travel-form-title {
			font-size: 28px;
			padding: 20px 0 20px 35px;
			letter-spacing: .05rem;
			font-weight: 200;
			color: $white;
		}

		.form-steps {
			text-align: center;
			color: $white;
			padding-right: 35px;

			.col-4 {
				min-width: 80px;
				padding: 0;
			}

			.timeline-digit {
				position: relative;
				font-size: 20px;
				font-weight: 500;
				border: 1px solid $white;
				border-radius: 100%;
				width: 40px;
				height: 40px;
				padding: 4px 0;
				margin: 0 auto 8px;

				&.first {
					animation: glowing 2.5s infinite;
					background-color: $white;
					color: $tripper4;
					z-index: 6;

					&::before {
						display: none;
					}
				}

				&::before {
					display: block;
					content: '';
					position: absolute;
					left: -41px;
					top: 50%;
					width: 40px;
					height: 1px;
					background-color: $white;
				}

				&.completed {
					animation: glowing 2.5s infinite;
					background-color: $white;
					color: $tripper4;
					z-index: 5;
				}

				&.active-step {
					animation: glowing 2.5s infinite;
					background-color: none;
					color: $white;
					z-index: 5;

					&::before {
						animation: glowing2 2.5s infinite;
					}
				}
			}

			.timeline-label {
				font-size: 11px;
				text-transform: uppercase;
			}
		}

		.nav-tabs {
			display: block;
			max-width: 100%;
			width: 100%;
			box-sizing: border-box;
			border: 0;
			position: absolute;
			bottom: 0;
			left: 0;

			li {
				display: block;
				height: 100%;
				min-height: 100%;

				a {
					display: block;
					position: relative;
					color: $white;
					text-transform: uppercase;
					font-weight: 400;
					font-size: .94rem;
					letter-spacing: .05rem;
					border-radius: 0;
					padding: 12px 20px 10px;

					&:visited {
						color: $white;
					}

					&.active,
					&:focus,
					&:hover {
						background-color: transparentize($tripper5, .05);
						outline: none !important;
						box-shadow: none;

						&::after {
							opacity: 1;
						}
					}

					&::after {
						border: 6px solid transparent;
						border-top-color: $white;
						bottom: -3px;
						content: '';
						height: 0;
						left: 0;
						margin: 0 auto;
						position: absolute;
						right: 0;
						width: 0;
						opacity: 0;
						transition: .3s all ease-in-out;
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-o-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						transform: rotate(180deg);
					}
				}
			}

			@media (max-width: 768px) {
				display: flex;
				align-items: stretch;
				justify-content: center;
				width: 100%;

				li {
					display: block;
					flex: 1 0 auto;
					list-style-type: none;

					a {
						display: flex;
						width: 100%;
						flex-direction: column;
					}
				}
			}
		}

		@media (max-width: 768px) {
			.col-md-8 {
				order: 2;
			}

			.col-md-4 {
				order: 1;
			}

			.travel-form-title {
				text-align: center;
				margin: 25px 0;
			}
		}
	}

	.tab-pane {
		clear: left;
		max-width: 100%;
		width: 100%;
		padding: 5px 0 0;
		box-sizing: border-box;
	}

	input {
		border-radius: 5px;
		height: 50px;
    color: $white;

		&:focus,
		&:hover {
			outline: none !important;
			box-shadow: none;
		}
	}

	select {
		border-radius: 5px;
		height: 50px;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: #fff url(../img/down_arrow.svg) no-repeat 95% center;
		background-size: 13px 8px;
    color: $white;
    font-weight: 700;

    option{
      font-weight: 700;

    }

		&:focus,
		&:hover {
			outline: none !important;
			box-shadow: none;
		}
	}

  select.tripType{
    height: 0px;
  }

	/* For IE10 */
	select::-ms-expand {
		display: none;
	}

	// .num-of-seats {
	// 	// padding-left: 40px;
	// }

	.chooseNoOfSeats {
		position: relative;

		// &::after {
		// 	background: url(../img/chair_icon.png) no-repeat 15px center;
		// 	position: absolute;
		// 	content: '';
		// 	width: 30px;
		// 	height: 20px;
		// 	left: 0;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// }
    select{
      border: none;
      background-position: 4.5rem center !important;
    }
	}

	.input-group {
		&:focus {

			.input-group-prepend,
			select{
				border-color: $tripper2 !important;
			}
		}
	}




	.input-group select.custom-select:focus, .arrive-date:focus, .return-date:focus{
		border-color: $tripper2 !important;
	}

	.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
	.react-datepicker__day--keyboard-selected{
		background-color: $tripper2 !important;
	}

	.react-datepicker-popper {
		z-index: 9;
	}

	.trip-date-picker-wrap {
		position: relative;

		.input-group-prepend {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			height: 50px;
			width: 40px;

			.input-group-text {
				border-right: 0;
			}
		}

		.react-datepicker__input-container {
			input {
				padding-right: 40px;
				background: $dark url(../img/calendar_icon.svg) no-repeat 95% center;
			}
		}
	}

	.input-group-prepend {
		background-color: transparent;
		padding-right: 0;
		border-radius: 0;
	}

	.input-group-text {
		background-color: transparent;
		padding-right: 0;
		border-radius: 0;

		i {
			color: $tripper2;
		}

		img {
			max-width: 100%;
			width: 18px;
			box-sizing: border-box;
		}
	}

	.submit-row {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		align-self: center;
		margin: 25px 0 0;
		padding: 25px 0 0;
		border-top: 1px solid $tripper-gray4;

		input {
			max-width: 200px;
			width: 100%;
			box-sizing: border-box;
			border: 1px solid $gray;
		}

		.submit-travel-form {
			background-color: $tripper7;
			color: $white;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: .025rem;
			font-size: 16px;
			border-radius: 3px;
			padding: 10px 75px;
			border-bottom: 2px solid darken($tripper7, 8);
			transition: .3s color ease-in-out, .3s background-color ease-in-out, .3s border ease-in-out;

			&:hover {
				border-bottom: 2px solid $tripper5;
				background-color: $tripper4;
			}
		}

		.groupon-label {
			display: inline-block;
			text-transform: uppercase;
			letter-spacing: .05rem;
			font-size: 14px;
			padding-right: 15px;
		}

		.groupon-wrap {
			position: relative;
			margin-right: 15px;
		}

		.add-groupon-code {
			position: absolute;
			right: 5px;
			top: 4px;
			background-color: $gray;
			color: $white;
			padding: 0 15px;
			border-radius: 5px;
			font-size: 26px;
			transition: .3s color ease-in-out, .3s background-color ease-in-out, .3s border ease-in-out;

			&:hover {
				background-color: $tripper7;
			}
		}
	}
}

.trip-section {
	padding: 0 16px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;

	.section-letter-label {
		float: left;
		position: relative;
		left: -25px;
		z-index: 5;
		background-color: transparentize($tripper4, .3);
		color: $white;
		padding: 0 5px;
		width: 48px;
		border-radius: 100%;
		text-align: center;

		div {
			text-align: center;
			display: inline-block;
			box-sizing: border-box;
			text-align: center;
			padding: 0;
			margin: 4px 0;
			border-radius: 100%;
			max-width: 100%;
			width: 100%;
			box-sizing: border-box;
			border: 2px solid $white;
		}

		&.alt {
			background-color: transparentize($tripper7, .3);
		}
	}

	.departing-trip-title {
		position: relative;
		font-size: 22px;
		color: $tripper1;
		font-weight: 300;

		.fa {
			margin-right: 5px;
		}

		// @media (max-width: 768px) {
		// 	padding-left: 50px;
		// }

		// &.step-a::before,
		// &.step-b::before {
		// 	position: absolute;
		// 	content: '';
		// 	width: 44px;
		// 	height: 44px;
		// 	top: -6px;
		// 	left: -48px;
		// 	z-index: 6;

		// 	@media (max-width: 768px) {
		// 		width: 30px;
		// 		height: 30px;
		// 		top: -2px;
		// 		left: 10px;
		// 	}
		// }

		// &.step-a::before {
		// 	background: url(../img/a.png) no-repeat;
		// 	background-size: contain
		// }

		// &.step-b::before {
		// 	background: url(../img/b.png) no-repeat;
		// 	background-size: contain
		// }

		.trip-loc {
			font-weight: 500;
		}
    .orangeArrow{
      margin: 0 10px;
    }
	}

	.departing-trip-wrap {
		padding-left: 90px;
		padding-right: 90px;
		padding-bottom: 40px;
		padding-top: 40px;
    background-color: $tripper-light-gray-new;
    border-radius: 1rem;


		&::after {
			position: absolute;
			left: 20px;
			width: 1px;
			border-left: 1px dashed #4bb9bd;
			top: 0;
			bottom: 0;
			content: none;
		}

		@media (max-width: 1023px) {
			padding-left: 20px;
			font-size: 13px;
			padding-right: 20px;
			border-left: 0;

			&::after {
				content: '';
				border-left: 0;
			}
		}
    @media screen and (max-width: 420px) {
      .grid-3{
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        justify-items: center;
        span{
          max-width: 110px;
        }
      }
      .date-controls{
        padding: 0 ;
        ul li a{
          padding: 16px 0 !important;
        }
      }
      .orangeArrow{
        margin: 0;
      }
    }

    @media screen and (min-width: 960px){
        max-width: 1140px !important;

    }
	}

	.date-controls {
		position: relative;

		ul {

			border-radius: 5px;
			margin: 30px 0;
			overflow: hidden;
      align-items: center;

			.date-controls-btn {
				position: relative;
				width: 25px;
				cursor: pointer;

				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: inline-block;
					color: #fff;
				}

				&.return-back {
					// background-color: $tripper-gray-new !important;
				}

				@media (max-width: 1023px) {
					width: 35px;
				}
			}

			li {
				&:first-child {
					a {
						border-radius: 5px 0 0 5px;
						border-left: 1px solid $tripper-gray5;
					}
				}

				&:last-child {
					a {
						border-radius: 0 5px 5px 0;
					}
				}

				&.inactive {
					a {
						cursor: not-allowed;
						color: #cfcfcf;
					}
				}

				a {
					letter-spacing: .025rem;
					border-radius: 0;
					color: $tripper1;
					background-color: $white;

					@media (max-width: 1023px) {
						padding: 20px 0 !important;
					}

					.date-controls-day {
						font-size: 18px;
						font-weight: 500;

						@media (max-width: 1023px) {
							font-size: 12px;
						}
					}

					.date-controls-date {
						font-size: 14px;

						@media (max-width: 1023px) {
							font-size: 12px;
						}
					}

					&.active,
					&.hover {
						color: $white;
						background-color: $tripper-gray-new;
					}

					@media (max-width: 1140px) {
						padding: .5rem 0;
					}

					@media (min-width: 768px) {
						padding: 30px 15px;
					}
				}
			}
		}

		&.alt {
			ul {
				li {
					a {

						&:hover,
						&.active {
							background-color: $tripper-gray-new;
						}
					}
				}
			}
		}
	}

	.pickup-details-row {
		margin: 0 0 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid $gray-500;

		.pickup-details {
			color: $tripper2;
			font-weight: 600;

			a {
				color: $tripper2;

				&:hover {
					color: $tripper-gray-new;
				}
			}
		}

		.fa-circle {
			position: relative;
			font-size: 6px;
			color: $gray-600;
			top: -3px;
			margin: 0 5px;

			@media(max-width:1023px) {
				display: block;
				visibility: hidden;
			}
		}
	}

	.trip-selector {
		.select-trip-row {
			border: 1px solid #dcdcdc;
      border-left: 1rem solid #D2D9E1;
      border-radius: 12px;
      box-shadow: 1px 3px 1px #D2D9E1;
      margin: 0 0 15px;

			&:hover {
				border-left: 16px solid $tripper2;
			}

			.cardAccordian {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
				width: 100%;
				cursor: pointer;
        background-color: $white;
        margin-left: 0;
        margin-right: 0;
        border-radius: 12px;
        justify-content: space-between;

        & > div:nth-child(7){
          grid-column-start: 6;
          align-self: center;
        }

        @media screen and (max-width: 767px){
          &{
            grid-template-columns: 1fr 10% 1fr;
            grid-template-rows: auto 10% 15% auto;
            grid-row-gap: 0;
          }

          & > div:nth-child(4){
            grid-row-start: 3;
          }
          & > div:nth-child(5){
            grid-row-start: 2;
            grid-column-start: 2;
            align-self: center;
           }
           & > div:nth-child(6){
            grid-row-start: 4;
            grid-column-start: 1;
            grid-column-end: 4;
            width: 90%;
            margin: 0 auto;
                 }
           & > div:nth-child(7){
            grid-row-start: 3;
            grid-column-start: 3;
            align-self: center;
                 }
        }

        @media screen and (min-width: 767px) and (max-width:  878px ) {
            &{
              grid-template-columns: 20% auto 20% auto 10% 1fr;
            }
        }

        }



      .features{
        display: grid;
        grid-template-columns: 1fr auto;
        width: 100%;
        text-align: left;
        align-items: center;
        height: auto;
        background-color: $tripper-light-gray-new;
        padding: 8px 20px;
        & span{
          justify-self: baseline;
        }
        & ul{
          justify-self: end;
        }

        @media screen and (max-width: 420px) {
          & span{
            font-size: 10px;
          }
          .route-details-menu{
          a img{
            width: 18px;
            height: 14px;
          }
        }
        }
      }

			.tripDetailsWrapper {
				border-top: 1px solid #dcdcdc;
				width: 100%;

				.tripDetailsRowWrap {
					padding: 20px;

					&.firstCol {
						border-right: 1px solid #dcdcdc;

						@media(max-width:767px) {
							border-right: 0;
							border-bottom: 1px solid #dcdcdc;
						}

					}
				}

				.tripDetailsRow {
          display: grid;
          grid-template-columns: 20% 20% 1fr auto;
          justify-items: start;
          font-size: 13px;
          align-items: center;
          text-align: left;
          padding: 0 20px;
          position: relative;
          width: 100%;
		  min-height: 52px;

					@media(max-width:1023px) {
						flex-direction: column;
						line-height: 1;
						padding-bottom: 15px;
					}

					&::before {
						position: absolute;
						content: '';
						width: 16px;
						height: 16px;
						top: 50%;
						transform: translateY(-50%);
						background-color: $tripper2;
						border-radius: 50%;
						left: 0;
					}

					&::after {
						position: absolute;
						content: '';
						top: 0;
						bottom: 0;
            border-left: 2px dotted $tripper2;
						left: 7px;
					}

					&:first-child {
						&::after {
							top: 13px;
						}

						&::before {
							@media(max-width:768px) {
								top: 10px;
							}
						}
					}

					&:last-child {
						&::after {
							bottom: 13px;
						}

						&::before {
							@media(max-width:768px) {
								bottom: 10px;
								top: unset !important;
							}
						}
					}

					div {
						padding-right: 20px;
						padding-top: 6px;
						padding-bottom: 4px;
						min-width: 80px;
						color: #868e96;

						&.time {
							font-weight: 500;
							color: #000000;
						}

						&.address {
							text-transform: capitalize;
						}

						&.address2 {
							min-width: 140px;
						}
					}

					a {
						color: $tripper2;
						padding-top: 6px;
						padding-bottom: 4px;
						display: inline-block;
					}

          @media screen and(max-width: 768px) {
            &{
              display: block;
            }
          }

				}

				.titleHead {
					color: #a6a6a6;
				}

				.tripDetailFooter {
					padding: 6px 15px;
					display: flex;
					font-size: 13px;
					color: #3e3e3e;
					align-items: center;
					background-color: #fafafa;
					position: relative;
					justify-content: space-between;

					@media(max-width:767px) {
						flex-direction: column;
						align-items: flex-start;
					}

					.btn {
						background-color: #4bb9bd;
						border-radius: 4px;
						padding: 10px 20px 8px;
						color: #fff;
						line-height: 1;
						font-size: 14px;
						text-transform: uppercase;
					}

					img {
						position: relative;
						top: -3px;
					}

					.tripUpdate {
						padding-bottom: 4px;
						padding-top: 4px;

						span {
							color: #faa61a;
						}
					}

					.tripExpress {
						padding-right: 20px;
						padding-bottom: 4px;
						padding-top: 4px;

						span {
							color: #439adb;
						}
					}
				}
			}

			.tripArrow {
				background: url(../img/rightGrayArrow.svg) no-repeat center;
				width: 30px;
				height: 30px;
			}

			.listToggleBtn {
				background: url(../img/arrow_up.png) no-repeat center;
				width: 30px;
				height: 30px;
				cursor: pointer;

				&.collapsed {
					background: url(../img/arrow_down.png) no-repeat center;
				}
			}

			.route-details-menu {
				margin-bottom: 0;
			}

			.routeDetailsHeading {
				font-size: 16px;
        		height: 10px;
				margin-bottom: 5px;
        @media screen and (max-width: 420px){
          & span {
            font-size: 12px;

          }

		  &{
			padding-top: 10px;
			margin-bottom: 0;

		  }
        }

			}

			.route-detail-item:not(:last-child) {
				margin-right: 15px;
        @media screen and (max-width: 420px){
          margin-right: 0;

        }
			}

			&.selected {
				 border-left: 1rem solid $tripper2;
			}

			&.alt {

				&.selected {
          border-left: 1rem solid $tripper2;
				}

				.btn {
					background-color: $tripper2;
					border-color: $tripper2;
					color: $white;

					&:hover {
						background-color: $tripper-gray-new;
						border-color: $tripper-gray-new;
						color: $white;
					}

					&.selected {
						background-color: $tripper-gray-new;
						border-color: $tripper-gray-new;
						color: $white;
						// padding: 10px 20px;
						width: 100%;
						box-sizing: border-box;
					}

					&.sold-out {
						border: 0;
						background-color: transparent;
						color: $black;
						text-transform: uppercase;
					}
				}
			}



			&.inactive {
				opacity: .5;
				pointer-events: none;
			}

			&.has-update-row {
				.update-row {
					font-size: 14px;
					font-weight: 400;
					margin-top: -5px;
					padding-bottom: 15px;

					.express-update {
						padding-right: 15px;

						strong {
							font-size: 16px;
							font-weight: 600;
							color: $tripper7;
						}

						i {
							position: relative;
							top: 2px;
							font-size: 22px;
							color: $tripper7;
						}
					}

					.schedule-update {
						strong {
							font-size: 16px;
							font-weight: 600;
							color: $tripper3;
						}

						i {
							position: relative;
							top: 2px;
							font-size: 22px;
							color: $tripper3;
						}
					}

					@media (max-width: 960px) {
						span {
							display: inline-block;
							max-width: 100%;
							width: 100%;
							box-sizing: border-box;
							text-align: center;
						}
					}
				}
			}

			&.is-cancelled {
				border-color: $tripper8;

				.btn {
					&.trip-cancelled {
						pointer-events: none;
						color: $tripper8;
						border-color: transparent;

						&:hover {
							background-color: transparent;
							border: 2px solid $tripper8;
							cursor: not-allowed;
						}
					}
				}
			}

			&.elite-bus {
				.deptarri-elite {
					display: inline-block;
					// background-color: $gray-900;
					color: $white;
					letter-spacing: .05rem;
					font-size: 12px;
					text-transform: uppercase;
					border-radius: 20px;
					max-width: 80%;
					width: 70px;
					padding: 3px 0 4px;
					box-sizing: border-box;
				}
			}
		}

		.select-trip-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;

      .selectTripBtn{
        width: 100%;
      }

			&.departarri-dept {
				color: $tripper-gray-dark2;
			}

			// &.deptarri-arri {
				// color: $tripper-gray-dark3;
			// }

			.deptarri-label {
				font-size: 12px;

				// @media(max-width:420px) {
				// 	font-size: 10px;
				// }
			}

			.deptarri-time {
				font-size: 22px;

				@media(max-width:420px) {
					font-size: 18px;
				}
			}

			.deptarri-loc {
				font-size: 14px;
        max-width: 120px;

				@media(max-width:420px) {
					font-size: 12px;
          max-width: 75px;
				}
			}

			&.deptarri-number {
				font-size: 17px;

				i {
					color: $gray-600;
					font-size: 24px;
					margin-top: 5px;
				}

				a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			&.deptarri-price {
				.deptarri-price-amount {
					font-size: 26px;
					color: $tripper-gray-dark2;
					line-break: 1;
				}

				.deptarri-price-label {
					font-size: 14px;
					color: $gray-600;
				}

				.deptarri-seats {
					letter-spacing: .05rem;
					font-size: 14px;
					color: $tripper-gray-dark2;

					strong {
						color: $tripper-gray-dark;
					}
				}

        @media screen and (max-width: 420px) {
          .deptarri-price-amount{
            font-size: 18px;
          }
        }
			}

			.route-details-menu {
				.route-detail-item {
					&.not-available {
						position: relative;

						&::before {
							content: '';
							background: url(../img/route_details_not_available.png) no-repeat;
							position: absolute;
							display: block;
							width: 20px;
							height: 20px;
							left: 0;
							top: 0;
							background-size: 20px;
						}
					}

				}


			}

			.btn {
				font-size: 14px;
				background-color: $tripper2;
				text-transform: uppercase;
				letter-spacing: .05rem;
        color: $white;
        width: 100%;
        padding: 10px 35px;
        border-width: 1px;

				&:hover {
					background-color: $tripper-gray-new;
					color: $white;
				}

				&.selected {
					background-color: $tripper-gray-new;
					// border-color: $tripper4;
					color: $white;
					box-sizing: border-box;
				}

        @media screen and (max-width: 420px) {
            &{
              padding: 4px 12px;
              height: 30px;
            }
        }
			}

			@media (max-width: 768px) {
				padding: 5px 0;
			}

			@media (min-width: 768px) {
				padding: 10px 10px;
			}
		}

    @media screen and (max-width: 420px) {

      &{
        .select-trip-row {
          border: 1px solid #dcdcdc;
          border-left: 5px solid #D2D9E1;
          border-radius: 5px;
          box-shadow: 1px 3px 1px #D2D9E1;
          margin: 0 0 15px;

          &:hover {
            border-left: 5px solid $tripper2;
          }
      }
    }
	}
}
}

.already-in {
	padding-bottom: 20px;

	.sign-out {
		color: #4b9fdc;
		cursor: pointer;
	}

	.signed-in {
		padding: 20px 0;

		&.sign-border {
			border-bottom: #e9eeee 1px solid;
		}

		.not-u {
			color: #8b8b8b
		}

		.email {
			color: #4b9fdc;
		}

		h3 {
			span {
				font-weight: normal;
				color: rgba(54, 54, 54, 0.25);
			}
		}

		.voucher-have {
			color: rgba(54, 54, 54, 1);
      margin-bottom: 1rem;
		}
	}

}

// Scroll to Top Button

.scroll-to-top {
	z-index: 1042;
	right: 1rem;
	bottom: 1rem;
	display: none;

	a {
		width: 3rem;
		height: 3rem;
		background-color: fade-out($gray-900, .5);
		line-height: 3rem;
	}
}

.btn-blue {
	background-color: $tripper2;
	border-radius: 4px;
	padding: 16px 20px 14px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), inset 0 1px 2px rgba(0, 0, 0, 0.15);
	color: #fff;
	line-height: 1;
	font-size: 14px;
	text-transform: uppercase;
}

.getMyTickets {
	text-align: center;
	color: $white;
	font-weight: 300;
	font-style: italic;
	padding-left: 40px;
	padding-right: 40px;

	.btn-orange {
		margin-left: 20px;
    background-color: $tripper2;
    color: $white;
	}
}
.route-ticket-details {
	@media screen and (min-width: 960px){
        max-width: 1140px !important;

    }
	width: calc(100% - 40px);
	padding: 20px;
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	margin-top: 30px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
	h1 {
	  font-size: 24px;
	  font-weight: bold;
	  color: #333333;
	  margin-bottom: 10px;
	  line-height: 1.2;
	}
  
	h3 {
	  font-size: 18px;
	  font-weight: 600;
	  color: #333333;
	  margin-bottom: 10px;
	}
  
	ul {
	  margin: 0;
	  padding-left: 20px;
	  list-style-type: disc;
  
	  li {
		font-size: 16px;
		color: #555555;
		line-height: 1.6;
		margin-bottom: 8px;
	  }
	}
  
	h6 {
	  font-size: 14px;
	  color: #777777;
	  margin-top: 15px;
	  line-height: 1.5;
	  text-align: left;
  
	  &:last-of-type {
		font-weight: bold;
		margin-top: 5px;
	  }
	}
  }
  
  .button {
	background-color: #ff6b4a;
	color: #ffffff;
	border: none;
	border-radius: 8px;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	transition: background-color 0.3s ease;
  
	&:hover {
	  background-color: #e55a3c;
	}
  }
  
  .route-details {
	margin-top: 10px;
	font-size: 14px;
	color: #888888;
  
	i {
	  margin-right: 8px;
	}
  }

.ReactPasswordStrength-strength-desc {
	display: none !important;
}

.ReactPasswordStrength-input {
	width: 100% !important;
}

.register-form {
	// background: url('../img/login_background.jpg') no-repeat center center;
	// background-size: cover;
	// min-height: 100vh;
	// padding: 160px 0;
	// margin-top: -40px;

	.login-wrapper {
		// max-width: 765px;
		margin: 0 auto;

		.bannerImage{
			background-color: #ECF2F7;
			padding: 2rem;
			background-size: contain;
			display: grid;
			align-items: center;

			img{
				width: 100%;
				height: auto;
			}

			@media screen and (max-width: 767px) {
				display: none;
			}

			@media screen and (min-width: 2000px){
				&{
				justify-items: center;
				height: 100vh;
				}

				img{
					height: auto;
					width: auto;
					max-height: calc(100vh - 90px);
				}
			}

			// @media screen and (min-width: 768px) and (max-width: 991px) {
			// 	display: grid;
			// 	align-items: center;
			// 	img{
			// 		height: 75%;
			// 	}
			// }

			// @media screen and (min-width: 420px) and (max-width: 767px) {
			// 	img{
			// 		height: 68%;
			// 	}
			// }
		}

		.bannerImageMobile{

			display: none;
			@media screen and (max-width: 767px) {
				display: block;
				margin: 0 auto;	
				background-size: contain;
				width: 100%;
				min-height: 179px;
			}
		}

		.login-header {
			text-align: left;
			padding: 35px;
			@media (max-height: 716px) {
				padding-top: 0!important;
				padding-bottom: 10px!important;
			  }

			span {
				color: #fff;
				font-weight: 300;
				line-height: 1;
				display: inline-block;
				font-size: 24px;
				vertical-align: middle;

				@media(max-width:580px) {
					font-size: 18px;
				}
			}

			.pgeTitle {
				margin-left: 20px;
				padding-left: 20px;
				border-left: 1px solid #b6c1c9;
			}
			@media (max-width: 767px) {
				padding: 35px 0;	
			}
		}

		svg {
			fill: #E8512F;
			height: 40px;

			@media(max-width:580px) {
				height: 30px;
			}
		}

		.loginFormWrapper {
			// box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			// background-color: #eeeeee;
			display: grid;
			grid-template-columns: 1fr 1fr;

			.bgLeft {
				background-size: cover;

				@media(max-width:580px) {
					display: none
				}
			}


			.bgRegisterLeft {
				background-color: #ECF2F7;
				padding: 2rem;
				background-size: contain;
				display: grid;
				align-items: center;
	
				img{
					width: 100%;
					height: auto;
				}
	
				@media screen and (max-width: 767px) {
					display: none;
				}
	
				@media screen and (min-width: 2000px){
					&{
					justify-items: center;
					height: 100vh;
					}
	
					img{
						width: 40%;
					}
				}
			}

			.bgRegisterLeftMobile{

				display: none;

				@media screen and (max-width: 767px) {
					display: block;
					margin: 0 auto;	
					width: 100%;
					min-height: 179px;
					border-radius: 8px;
					padding-top: 1rem;
					padding-bottom: 1rem;
	
				}

				@media screen and (min-width: 420px) and (max-width: 767px) {

				}

			}

			.bgForgotLeft {
				background: url('../img/forgot_password_bg.jpg') no-repeat center center;
				background-size: cover;
				margin: 2rem;
				border-radius: 12px;

				@media(max-width:580px) {
					display: none
				}
			}

			.loginForm {
				display: grid;
				grid-template-rows: auto 1fr;
				background: url('../img/elipsis.svg') no-repeat left bottom;

			@media screen and (min-width: 2000px){
				align-items: center;
			}
				form{
					// align-self: center;
					@media screen and (min-width: 768px) {
						padding: 6% 10%;
					}
				}

				h4 {
					color: #13191d;
					font-weight: 600;
					font-size: 2rem;
					margin-bottom: 15px;
				}

				p {
					color: #59656d;
					font-size: 13px;
				}

				.subtitle{
					color: #292731;
					font-size: 18px;
					font-weight: 400;
					padding-bottom: 2rem;
				}

				.form-control {
					border-color: #ECF2F7;
					background-color: #ECF2F7;
					padding: 10px 15px;
					height: auto;
					-webkit-appearance: none;

					&:focus {
						border-color: #4ab9bd;
					}
				}

				.btn-blue {
					width: 100%;
				}

				.formLable {
					font-size: 18px;
					color: #455360;
					font-weight: 600;
				}

				.forgot-link {
					color: $tripper2;
					font-size: 18px;
					font-weight: 600;
				}

				.register-link {
					color: $tripper2;
					font-weight: 600;
				}

				.ReactPasswordStrength-input {
					width: 100%
				}

				.ReactPasswordStrength-strength-desc {
					width: auto;
				}

				.strong-password {
					font-size: 13px;
					padding-top: 10px;
					color: #848f98;
					font-weight: 300;
					line-height: 1.15;
				}
			}

			.forgotForm {
				padding: 100px 50px;
				min-height: 458px;
				display: flex;
    			align-items: center;
			}

			.center{
				text-align: center;
				padding-bottom: 1rem;
			}

			.copyright{
				margin-top: -40px;

				@media screen and (min-width: 768px) {
					padding-left: 18%;
				}
				@media screen and (max-width:767px) {
					margin-top: 1rem;
				}
			}

			@media screen and (max-width: 767px) {
				&{
					grid-template-columns: 1fr;		
					padding: 1.5rem;
					.loginForm{
						background: none;
					}
				}
			}
		}


	}
	@media screen and (max-width: 767px) {
		&{
			background: url('../img/inverted-elipsis.svg') no-repeat right top;
			background-size: 12rem;
		}
		
	}
}

.confirmTrip {
	background-color: #ffffff;
  .tripper-card{
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px rgba(78, 88, 100, 0.18);
    border-left: 12px solid rgba(78, 88, 100, 0.18);
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .tripper-card:hover{
    border-left: 12px solid $tripper2;

  }
	.edit-trip-btn {
		z-index: 1;
	}

	background-color: #fff;
	padding: 10px 25px 75px 45px;
	box-sizing: border-box;
	border-radius: 0 0 8px 8px;
	color: #363636;
	position: relative;

	// &::after {
	// 	box-shadow: 0 -25px 40px 0 rgba(0, 0, 0, 0.1);
	// 	position: absolute;
	// 	content: '';
	// 	left: 0;
	// 	top: 0;
	// 	right: 0;
	// 	height: 210px;
	// }

	// &::before {
	// 	position: absolute;
	// 	content: '';
	// 	border-left: 1px dashed #4bb9bd;
	// 	top: 30px;
	// 	bottom: 0;
	// 	left: 0px;
	// 	width: 1px;

	// 	@media(max-width:768px) {
	// 		border-left: 0;
	// 	}
	// }

	.bold {
		font-weight: 600;
	}

	.btn-blue {
		background-color: $tripper2;
		border-radius: 4px;
		padding: 12px 20px 10px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), inset 0 1px 2px rgba(0, 0, 0, 0.15);
		color: #fff;
		line-height: 1;
		font-size: 14px;
	}

	.green-text {
		color: #4bb9bd;
	}

  .description {
    color: #97a1a8;
    font-size: 13px;
  }

	.blue-text {
		color: #4b9fdc;
	}

	.content-header {
		border-bottom: #e9eeee 1px solid;
		padding: 15px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 22px;
		position: relative;

		h4 {
			margin-bottom: 0;
		}
	}

	// .step-a::before,
	// .step-b::before,
	// .step-c::before,
	// .step-d::before {
	// 	position: absolute;
	// 	content: '';
	// 	width: 44px;
	// 	height: 44px;
	// 	top: 12px;
	// 	left: -66px;
	// 	z-index: 6;
	// }

	// .step-a::before {
	// 	background: url(../img/a.png) no-repeat;
	// }

	// .step-b::before {
	// 	background: url(../img/b.png) no-repeat;
	// }

	// .step-c::before {
	// 	background: url(../img/c.png) no-repeat;
	// }

	// .step-d::before {
	// 	background: url(../img/d.png) no-repeat;
	// }

	.tripDetails {
		color: #242424;
		font-size: 15px;

		.seatBook {
			padding: 20px 0;
			font-size: 15px;
			margin: 0;
		}

		p {
			margin-bottom: 0;
		}

		.departingTrip,
		.returningTrip {
			padding-left: 40px;

			&::after {
				background: url(../img/arrow_up_black.png) no-repeat left top;
				content: '';
				width: 12px;
				height: 14px;
				position: absolute;
				top: 4px;
				left: 15px;
			}
		}

		.returningTrip {
			&::after {
				transform: rotate(180deg);
			}
		}

		.tripType {
			font-size: 18px;
			font-weight: 300
		}

		.tripAddress {
			font-size: 18px;
			font-weight: 600
		}

		.pickUp {
			padding: 15px 0 5px;
		}

		.dropOff {
			padding: 5px 0 5px;
		}
	}

	.customerInformation {

		padding: 1rem;

		.lSecHeader {
			border-bottom: #e9eeee 1px solid;
			padding: 15px 0;
			margin-bottom: 10px;
			position: relative;

			h4 {
				margin-bottom: 0;
				font-size: 20px;
				font-weight: 400;
				color: #4E5864;

				@media screen and (max-width:1199px){
					text-align: center;
				}
			}

			p, span {
				color:  #4E5864
			}

			span{
				font-size: 18px;
				font-weight: 600;

				@media screen and (max-width: 1199px) {
					font-size: 14px;
				}
			}
		}

		.rememberme{
			display: flex;
			align-items: center;
			gap: 5px;
			color: #8b8b8b;
		}

		.text-end{
			text-align: end;
		}

		p {
			margin-bottom: 0;
		}

		.optional {
			color: #b6c1c9;
		}

		.formTitle {
			font-size: 18px;
			padding: 10px 0;
		}

    .resetPassword{
      color: $tripper2;
      cursor: pointer;
    }

    .loginForm p {
      color: #59656d;
      font-size: 13px;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

	.form-control{
		box-shadow: none;
	}

	.register-link{
		padding-top: 1rem;
		text-align: center;
	}


	.checkmark{
		background: #fff !important;
    	border: 1px solid $tripper2 !important;
	}


	button{
		@media screen and (max-width: 420px) {
			width: 100%;
		}
	}

	}

	.customerInformation.tripper-card{
		padding: 0;

		.lSecHeader.step-c{
			background-color: #fff;
			padding: 1rem;

		}

		& > div:last-child{
			padding: 1rem;
		}
	}

	.divider{
		border-bottom: 1px solid #dee2e6;
	}

	.review{
		background-color: #F9FAFB;
	}

	.heading4 {
		margin-bottom: 0;
		font-size: 20px;
		font-weight: 400;
		color: #222222;
	}

	.createAccount {
		padding: 10px 0;
		display: block;
		color: #363636;
		font-size: 14px;
		margin-bottom: 20px;

		a {
			color: $tripper2;
		}

		.terms{
			display: block;
			margin-left: 2rem;
		}
	}

	.registerForm {
		color: #222222;

		.two-col{
			display: grid;
    		grid-template-columns: 1fr auto;

			span.edit{
				color: $tripper2;
				cursor: pointer;
			}
		}
		

		.lSecHeader {
			padding: 25px 0;
			font-size: 18px;
			font-weight: 500;
			color: #4E5864;

			.or {
				color: #b8b8b8;
				font-style: italic;
			}
		}

		.stayUpdate {
			border-left: #e9eeee 1px solid;
			padding: 0 0 15px 20px;

			h4 {
				margin-bottom: 0;
				font-size: 20px;
				font-weight: 400;
				color: #222222;
			}

			p {
				color:  #8b8b8b
			}
		}
	}


	.form-control {
		border-radius: 0;
		padding: 12px 15px;
		border-color: #c0cdce;
		box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.15);
		height: 48px;
		-webkit-appearance: none;

		&:focus {
			border-color: #4ab9bd;
		}

		&.error {
			border-color: red;
		}
	}

	.form-control.username {
		background: #ffffff url(../img/email.svg) no-repeat 15px center;
		padding-left: 40px;
	}

	.form-control.password {
		background: #ffffff url(../img/lock.svg) no-repeat 15px center;
		padding-left: 40px;
	}

	select.form-control {
		min-height: 50px;
		background: #ffffff url(../img/grey_arrow.png) no-repeat 95% center;
	}

	.formLable {
		font-size: 13px;
		color: #222222;
	}

	.os{
		position: -webkit-sticky;
		position: sticky;
		top: 100px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
		background-color: #fff;
		border: #d9d9d9 1px solid;
		max-width: 290px;
		margin-left: 1rem;
		margin-right: 1rem;
		display: grid;
		margin-bottom: 2rem;
		z-index: 2;

		.head{
			background: #252F3A;
			color: #fff;
		
		}
	}

	.order-summary {
		

    .grid{
      display: grid;
      padding: 10px;
    }

    .head-strip{
      font-weight: 700;
	  .subtitle-summ{
		color: #000000;
		font-weight: 400;
		.subtitle{
			font-weight: 700;
			color: #000000;

		}
	  }

      @media screen and (max-width:1200px) {
		padding-top: 0;
        span{
			font-size: 1.1rem;
          	color: $tripper2 ;
        }
      }
    }


    .trip-summary{
      grid-template-columns: auto auto;
      background-color: #F9FAFB;

    }

	.tripsum{
		background-color: #F9FAFB;
	}

    .price-summary{

		&{
			display: grid;
			grid-template-columns: 15% 1fr 1fr;
			align-items: center;
		}

		& div:nth-child(3){
			justify-self: end;
		}
		& div:nth-child(2){
			justify-self: start;
		}
	 
	@media screen and (max-width:1199px){
			
		grid-template-columns: 1fr 1fr;
		align-items: center;

		img{
			padding-left: 1rem;
		}

		& div:nth-child(2){
			justify-self: end;
			font-weight: 700;
		}

		&.border-bottom{
		border-bottom: 1px solid rgb(164, 164, 164);
		}
	}
    }

	.mobile-divider{
		@media screen and (max-width:1200px) {
			border-bottom: 1px solid #dee2e6;
			height: 0;
		}
	}

    .final-summary{
      font-weight: 700;
      grid-template-columns: auto auto;

	  div{
		font-size: 1rem;
	  }

      & div:nth-child(2){
        justify-self: end;
      }
    }

    .depart-trip-time, .depart-trip-time-data {
      display: grid;
      grid-template-columns: 1fr 1fr auto 1fr;
	  align-items: center;
      font-weight: 300;
	  .city{
	  text-transform: capitalize;
	  }
    }

	.depart-trip-time-data, .depart-trip-time{
		& div:nth-child(4){
			justify-self: end;
		}
	}

    .depart-trip-time-data{
      font-weight: 600;
    }

		table {
			width: 100%;
			font-size: 15px;

			.heading {
				font-size: 22px;
				padding-bottom: 10px;
			}

			td {
				padding: 0 0 10px;
				font-weight: 400;
				color: #222222;
				vertical-align: top;

				span {
					color: #616161;
					display: block;
					line-height: 1;
					font-size: 14px;

					&.inline-ele {
						display: inline !important;
					}
				}
			}

      .credit-success{
        color: green;
      }

			.wd70 {
				width: 70%;
			}

			.discount {
				color: #ea5645;
			}

			.totalPrice {
				color: #393b3c;
				font-size: 34px;
				font-weight: 400;
				border-top: #d9d9d9 1px solid;
				padding-bottom: 0;
				padding-top: 5px;

				sup {
					font-size: 20px;
				}
			}
		}

		.elite-icon {
			display: inline-block;
		    background-color: #212529;
		    color: #fff;
		    font-size: 12px;
		    border-radius: 20px;
		    padding: 1px 4px 2px;
			img {
				width: 30px;
				height: 15px;
			}
		}
	}

	// .content-body{
	// 	position: fixed;
	// 	top: calc(100vh - 4rem);
	// 	left: 0;
	// 	width: 100%;
	// 	z-index: 10;
	// 	height: 100%;
	// 	display: block;
	// }

// 	.scroll-top{
// 		@media screen and (max-width: 1200px) {
			
// 		top: calc(100vh - 34rem);
// 		animation: scrollup 0.1s ease-in;

// 		.head-strip{
// 			.subtitle-summ{
// 				display: none;
// 			}
// 		}

		
// 		.shadow{
// 			position: absolute;
// 			height: 100vh;
// 			width: 100%;
// 			top: -100vh;
// 			z-index: 1;
// 			background-color: rgba(0, 0, 0, 0.79);
// 			left: 0;
// 		}
	
// 	}

// 	@keyframes scrollup {
// 		// 0%   {top: 90%;}
// 		// 25%  {top: 75%;}
// 		// 50%  {top: 65%;}
// 		// 50%  {top: 55%;}
// 		// 100% {top: 35%;}
// }}
}

/* The container */
.checkbox, .radio {
	position: relative;
	padding-top: 7px;
	padding-bottom: 7px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.radio{
	padding-left: 30px !important;
	padding-right: 30px !important;
}


.typeText {
	padding-right: 15px;
}

/* Hide the browser's default checkbox */
.checkbox input,
.radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 50%;
	margin-top: -11px;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: #eee;
}

.radio .checkmark {
	border-radius: 50%;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked~.checkmark,
.radio input:checked~.checkmark {
	// background-color: $tripper2 !important;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid $tripper2;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.radio .checkmark:after {
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    border-radius: 100%;
	background: $tripper2;
}

/* Show the checkmark when checked */
.checkbox input:checked~.checkmark:after,
.radio input:checked~.checkmark:after {
	display: block;
}

.confirmTrip+section {
	padding: 0;
}

.editTrip {
	&::before {
		content: none;
	}

	.text-center {
		text-align: center;
	}

	.text-right {
		text-align: right;
	}

	.editTripBox {
		max-width: 80%;
		margin: 50px auto;
		background-color: #f7f7f7;
		padding: 50px;
    border-left: 12px solid orangered;
    border-radius: 12px;

		@media(max-width:1024px) {
			padding: 30px;
			max-width: 100%;
		}

    @media screen and (max-width:420px) {

       .header-content{
        font-size: 18px;
          p{
            font-size: 14px;
          }
       }

       .tripDetails {
        .tripType{
          font-size: 16px;
        }
        p{
          font-size: 12px;
        }

        .callUs{
          h5{
            font-size: 16px;
          }
        }

       }
       .ticketCancelChangeBox{
        button.btn-blue{
          padding: 10px;
        }
      }
    }
	}

	.btn-blue {
		padding: 18px 20px 16px
	}

	.mb-15 {
		@media(max-width:768px) {
			margin-bottom: 15px;
		}
	}

	.edit-trip-button {
		@media(max-width:768px) {
			text-align: center;
		}
	}

	.formLable {
		color: #222222;
		font-size: 12px;
	}

	.header-content {
		border-bottom: #d7dada 1px solid;
		padding-bottom: 5px;
		margin-bottom: 25px;

		h4 {
			font-weight: 400;
			font-size: 22px;

			strong {
				color: #222222
			}

			color:#5d5d5d
		}

		p {
			font-size: 15px;
			color: rgba(54, 54, 54, 0.5);
			margin-bottom: 10px;
		}
	}

	.note {
		font-size: 12px;
		color: #999999;
		font-style: italic;
		padding-top: 15px;
	}

	.optional-account {
		h4 {
			font-weight: 500;
			font-size: 18px;
			margin-bottom: 15px;

			span {
				color: #b6c1c9;
				font-weight: 300;
			}

			color:#222222
		}
	}
}

.cancel-btn {
	background-color: #ef315b !important;
}

.inline {
	display: inline-block;
}

.reservation {
	padding-top: 0;
	margin-bottom: 120px;

	h4 {
		font-weight: 400;
		font-size: 22px;

		strong {
			color: #222222
		}

		color:#5d5d5d
	}

	.btn-blue {
		margin-right: 10px;
	}

	.table-custom {
		margin-bottom: 25px;

		.table-row {
			background-color: white;
			margin-bottom: 10px;
			border: 1px solid #eee;
			border-radius: 4px;
			align-items: center;
			margin: 0 0 10px;

			.col-md-3,
			.col-md-2,
			.col-md-4 {
				padding: 15px;

				.btn-blue {
					padding: 16px 10px 14px;
				}

			}
		}
	}

	.callUs {
		h5 {
			font-weight: 400;
			font-size: 16px;
			color: #5d5d5d;
			margin-bottom: 4px;
		}

		p {
			color: #999999;
			font-size: 14px;
			margin: 0;
		}
	}
}

@media (max-width: 1200px) {
	#menu-main-menu {
		border-top: 1px solid #868e96;

		li {
			a {
				padding: 10px 0;
			}

			&:first-child {
				a {
					padding-left: 15px;
				}
			}
		}
	}

	.confirmTrip {
		padding: 10px 10px 50px 35px;
		font-size: 13px;

		.form-control {
			font-size: 13px;
		}

		.step-a::before,
		.step-b::before,
		.step-c::before,
		.step-d::before {
			left: -56px;
			top: 6px;
		}

		.step-a::before {
			top: 20px;
		}

		.tripDetails {
			font-size: 13px;

			.tripType {
				font-size: 16px;
			}

			.tripAddress {
				font-size: 14px;
			}
		}

		.customerInformation {
			.lSecHeader {
				padding: 10px 0;

				h4 {
					font-size: 16px;
				}
			}

			.formTitle {
				font-size: 14px;
			}
			
		}

		.registerForm {
			padding-right: 15px;

			.lSecHeader,
			.stayUpdate h4 {
				font-size: 14px;
			}

		}

		.order-summary {
			padding: 10px;
			max-width: 100%;

			table,
			span {
				font-size: 12px;
			}
		}

		.order-summary.only-mobile{
			min-height: 22rem;
			background-color: #F9FAFB;


			@media screen and (max-width: 1199px) {
				display: grid !important;
				
				.line{
					width: 70px;
					height: 4px;
					background: gray;
					margin: 0 auto;
					border-radius: 9px;
					margin-top: 0;
					padding: 0;
					opacity: 0.5;
				}
			}

			.head-strip{
				grid-template-columns: 1fr auto;
			}
		}

		.content-header {
			font-size: 18px;
		}

		.formLable {
			font-size: 12px;
		}
	}
}

@media (max-width: 768px) {
	#travel-form-region {
		.tabs-wrapper {
			flex-direction: column;
			height: auto;
			padding-bottom: 0;

			.travel-form-title {
				padding: 10px 0;
				margin: 0;
				font-size: 22px;
				letter-spacing: normal;
			}

			.form-steps {
				padding-right: 0;
				margin-bottom: 15px;

				.col-4 {
					min-width: 75px;
				}

				.timeline-digit {
					width: 30px;
					height: 30px;
					font-size: 13px;

					&::before {
						left: -46px;
						width: 45px;
					}
				}
			}

			.nav-tabs {
				position: relative;

				li {
					a {
						font-size: 13px;
						padding: 10px;
					}
				}
			}
		}

		input,
		select {
			font-size: 13px;
		}

		.tab-content {
			padding: 20px 15px;
		}

		.trip-date-picker-wrap {
			display: block;
		}

		.react-datepicker__input-container,
		.react-datepicker-wrapper {
			display: block;
		}
	}

	.confirmTrip {
		padding-left: 10px;

		// &::before {
		// 	left: 20px;
		// }

		// .step-a::before,
		// .step-b::before,
		// .step-c::before,
		// .step-d::before {
		// 	width: 0px;
		// 	height: 0px;
		// 	left: -26px;
		// 	background-size: 24px auto;
		// }
	}
}

@media (max-width: 1024px) {
	.trip-section {
		.departing-trip-title {
			font-size: 16px;
		}
	}
}

.editTripBox {
	.tripDetails {
		color: #242424;
		font-size: 15px;
		padding-bottom: 50px;
		align-items: center;

		.seatBook {
			padding: 20px 0;
			font-size: 15px;
			margin: 0;
		}

		p {
			margin-bottom: 0;
		}

		.gray-text {
			color: $tripper-gray-dark3;
      font-weight: 600;
		}

		.tripType {
			font-size: 18px;
			font-weight: 300
		}

		.tripAddress {
			font-size: 18px;
			font-weight: 600
		}

		.pickUp {
			padding: 15px 0 5px;
		}

		.dropOff {
			padding: 5px 0 5px;
		}
	}

	.bold {
		font-weight: 600;
	}

	.callUs {
		padding: 20px 10px 20px 65px;
		background: url(../img/info_icon.png) no-repeat left center;

		h5 {
			font-weight: 400;
			font-size: 20px;
			color: #5d5d5d;
			margin-bottom: 4px;
		}

		p {
			color: #999999;
			font-size: 14px;
			margin: 0;
		}
	}
}

.ticketCancelChangeBox {
	background-color: #ffffff;
	padding: 20px 20px 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	position: relative;

	p {
		font-size: 14px;
		color: rgba(54, 54, 54, 0.75);
		margin-bottom: 10px;
	}

	.col-md-5,
	.col-md-2 {
		padding-right: 5px;
		padding-left: 5px;
	}

	.row {
		margin-right: -5px;
		margin-left: -5px;
	}

	.form-control {
		border-radius: 0;
		padding: 12px 15px;
		border-color: #c0cdce;
		box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.15);
		height: auto;
		-webkit-appearance: none;

		&:focus {
			border-color: #4ab9bd;
		}
	}

	select.form-control {
		min-height: 50px;
		padding-left: 44px;
		text-align: center;
		background: #ffffff url(../img/grey_arrow.png) no-repeat 92% center;
	}

	.chairIcon {
		position: absolute;
		left: 20px;
		top: 15px;
	}
}

.confirmedTrip {
	margin-top: -150px;
	background-color: #fff;
	padding: 40px;
	box-sizing: border-box;
	border-radius: 0 0 8px 8px;
	color: #363636;
	position: relative;

	h4 {
		font-weight: 500;
		color: #363636;
		padding: 0 0 10px;
		font-size: 20px;
		margin: 0;
	}

	&:after {
		box-shadow: 0 -25px 40px 0 rgba(0, 0, 0, 0.1);
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		right: 0;
		height: 210px;
	}

	.content-head {
		max-width: 70%;
		margin: auto;
		padding: 0 0 50px;

		h2 {
			font-size: 32px;
			text-transform: uppercase;
			color: #469fdd;
			font-weight: 300;
			margin: 0;
		}

		h4 {
			font-weight: 500;
			color: #363636;
			padding: 10px 0;
			margin: 0;
		}

		p {
			line-height: 1.5;
			color: #363636;
		}

	}

	.tripDetails {
		p {
			margin: 0;
		}

		.tripDate {
			font-weight: 300;
			font-style: italic;
			font-size: 13px;
			padding: 10px 0;
		}

		.tripType {
			font-weight: 600;
			padding: 0 0 10px;
		}

		.bold {
			font-weight: 600;
		}

		.pickUp,
		.dropOff {
			font-weight: 300;
			font-style: italic;
			font-size: 13px;
		}

	}

	.blue-text {
		color: #469fdd;
	}

	.buttonWrapper {
		text-align: center;
		padding: 50px 0;

		.btn {
			margin: 5px;
		}
	}

	.btn-green {
		background-color: #4bb9bd;
	}

	.hotels {
		height: 160px;
	}

	.capitalize {
		text-transform: capitalize;
	}
}

sup {
	font-size: 50%;
}

/*Manage Account*/
.manage-account {
	position: relative;
	// margin-top: 64px;

	@media (max-width: 992px) {
		margin-top: 48px;
	}

	&::after,
	&::before {
		content: '';
		position: absolute;
		height: 100%;
		top: 0;
		z-index: 2;
	}

	&::after {
		left: 0;
		width: 30%;
		background-color: #f7f7f7;

		@media (max-width: 767px) {
			background-color: #fff;
		}
	}

	&::before {
		background-color: #fff;
		left: 30%;
		width: 70%;
	}

	.container {
		background-color: #fff;
		position: relative;
		z-index: 3;
	}

	.reservation-label {
		display: inline;
	}

	.left-navbar {
		padding: 120px 0;
		min-height: 80vh;
		background: #f7f7f7 url('../img/side_bg.jpg') repeat-y right top;

		@media (max-width: 992px) {
			padding: 60px 0;
		}

		@media (max-width: 767px) {
			background: #f7f7f7;
			min-height: inherit;
			padding: 0;
			margin: 20px 0;
		}

		.nav-item {
			border: 1px solid transparent;
			border-bottom-color: #e1e1e1;

			&:first-child {
				border-top: 1px solid #e1e1e1;
			}

			a {
				display: block;
				padding: 20px 30px;
				font-weight: 400;
				color: #4d4d4d;
				font-size: 18px;

				@media (max-width: 992px) {
					font-size: 16px;
					padding: 15px 10px;
				}
			}

			span {
				display: block;
				padding: 20px 30px;
				font-weight: 400;
				color: #4d4d4d;
				font-size: 18px;
				cursor: pointer;

				@media (max-width: 992px) {
					font-size: 16px;
					padding: 15px 10px;
				}
			}

			&.active {
				border-left: 1px solid #e1e1e1;
				background: #fff url('../img/rightOrangeArrow.svg') no-repeat 95% center;
        background-size: 20px;

				@media (max-width: 767px) {
					border-left: 0;
				}

				a {
					color: $tripper2;
				}
			}
		}
	}

	.page-title {
		height: 121px;
		font-size: 28px;
		color: #4d4d4d;
		border-bottom: 1px solid #e1e1e1;
		padding: 0 40px;
		display: flex;
		align-items: center;

		@media (max-width: 992px) {
			height: 61px;
			font-size: 22px;
		}

		@media (max-width: 767px) {
			padding: 0;
		}
	}

	.manage-account-content {
		padding: 0;

		.overview {
			padding: 40px 0 40px 60px;
			color: #222222;

			@media (max-width: 992px) {
				padding: 0px 0 40px 40px;
			}

			@media (max-width: 767px) {
				padding-left: 0;
			}

			.heading {
				color: #222222;
				font-weight: 300;
				border-bottom: 1px solid #e1e1e1;
				padding: 20px 0;
				font-size: 22px;
				position: relative;
				margin-bottom: 20px;
				line-height: 1;

				@media (max-width: 992px) {
					font-size: 18px;
				}

				@media (max-width: 767px) {
					padding-left: 20px;
				}

				&::after {
					position: absolute;
					width: 8px;
					height: 8px;
					left: -20px;
					background-color: $tripper2;
					content: '';
					top: 50%;
					margin-top: -4px;

					@media (max-width: 767px) {
						left: 0;
					}
				}

				span {
					font-weight: 400;
					text-transform: capitalize;
				}
			}

			.vouchers {
				font-size: 28px;
				font-weight: 100;

				.pointsNum {
					min-height: 60px;
				}

				.points {
					color:  $tripper-gray-dark2;
					font-size: 14px;
          font-weight: 700;
				}
			}
		}
	}

	.upcoming {
		ul {
			border: #dde0e1 1px solid;
			margin-bottom: 15px;
		}

		@media(max-width:410px) {
			.card-header {
				flex-direction: column;
				align-items: center;
			}
		}
	}

	.free-ticket-info {
		background: url('../img/rightOrangeArrow.svg') no-repeat 0px 8px;
    background-size: 30px 30px;
		color:  $tripper2;
		font-size: 16px;
		padding: 10px;
		font-weight: 300;
		padding-left: 40px;

		.youhaveearn {
			color: #8a8b8a;
			font-size: 14px;
			padding-top: 4px;

			span {
				font-weight: 500;
			}
		}
	}

	.card {
    border: none;
		border-left: 12px solid $tripper2 ;
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(78, 88, 100, 0.18);
    margin-bottom: 30px;

		.card-header {
			background-color: #f4f7f8;
			border-bottom-color: #eeeff0;
			border-radius: 0;
			display: flex;
			justify-content: space-between;

			@media (max-width: 767px) {
				padding: .5rem .25rem;
			}

			.dropdown:hover .dropdown-menu {
				display: block;
				left: inherit;
				right: 0;
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
				border-color: #eaeaea;
				min-width: 210px;
				padding: 15px 5px;
				top: 20px;

				a {
					padding: 3px 15px;
					color: #666e75;
					display: block;
					font-size: 13px;

					&:hover {
						color: $tripper2;
					}

					&.active {
						color: $tripper2;
					}
        }
        @media (max-width: 410px) {
          left: -65px;
        }
			}

			.res-number {
				background-color: #ffffff;
				padding: 7px 15px 5px;
				border-radius: 25px;
				font-size: 12px;
        font-weight: 600;
				line-height: 1;

				@media (max-width: 767px) {
					font-size: 12px;
					padding: 7px 5px 5px;
				}

				@media (max-width: 360px) {
					font-size: 10px;
				}

				span {
					color: #666e75;
				}
			}

			.view-history {
				background: url('../img/view_icon.png') no-repeat left center;
				padding-left: 20px;
				color: #439bdc;
				display: inline-block;
				padding-right: 10px;
				border-right: 1px solid #ccc;
				margin-right: 10px;
				line-height: 1;
				font-size: 13px;
				display: none;
			}

			.print-details {
				background: url('../img/print_icon.jpg') no-repeat left center;
				padding-left: 26px;
				color: #439bdc;
				display: inline-block;
				line-height: 1;
				font-size: 13px;
				padding-top: 4px;
				padding-bottom: 4px;
			}

			.options {
				padding-left: 20px;
				color: #000000;
				display: inline-block;
				font-size: 13px;
				line-height: 1;
        font-weight: 600;
			}
		}

		.card-body {
			.sub-fields {
				font-size: 12px;
				color: #8a8b8a;
				font-weight: 600;

				@media (max-width: 576px) {
					display: inline-block;
					padding-right: 5px;
				}
			}

			@media(max-width:767px) {
				.mb-2 {
					margin-bottom: 0 !important;
				}
			}

			.sub-fields-value {
				font-size: 14px;
				color: $tripper-gray;
				font-weight: 500;

				@media (max-width: 576px) {
					display: inline-block;
					font-size: 12px;
				}
			}

			.active {
				color: #6cbf4a;
			}
		}
	}

	.reservation-history {
		.table {
			th {
				font-size: 11px;
				color: rgba(34, 34, 34, 0.75);
				border: 0;
				font-weight: 300;
			}

			tbody {
				tr:nth-child(odd) {
					background-color: #f8fafb;
				}

				td {
					font-size: 13px;
					border: 0;
					padding: 1.1rem .75rem;
					vertical-align: middle;
					color: #8a8b8a;
					font-weight: 300;
				}

				.active {
					color: #6cbf4a;
				}

				.complete,
				.rescheduled,
				.onhold {
					color: #8a8b8a;
				}

				tr.completed {
					td {
						color: $tripper2;
					}

					.reservation-num {
						color: #00607f;
					}

					.active {
						color: #6cbf4a;
					}

					.complete,
					.rescheduled,
					.onhold {
						color: #8a8b8a;
					}
				}

				.account-credited-wrap {
					position: relative;

					.account-credited {
						position: absolute;
						border-radius: 25px;
						background-color: #fff;
						padding: 6px 15px;
						color: #8a8b8a;
						min-width: 175px;
						bottom: -35px;
						right: -60px;
						font-size: 12px;
						box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
						text-align: center;
						line-height: 1;

						.amount-cre {
							color: #6cbf4a;
							margin-left: 10px;
						}
					}
				}
			}
		}

		.setting-btn {
			display: inline-block;
			width: 20px;
			height: 20px;
			background: url(../img/settings_grey_icon.png) no-repeat center;
			margin: 0 auto;
			position: relative;
			cursor: pointer;

			&:hover {
				background: url(../img/settings_blue_icon.png) no-repeat center;
			}
		}

		.card {
			.card-header {
				// border-left: 6px solid;
				align-items: center;

				.status {
					text-transform: uppercase;
					font-size: 13px;

					@media (max-width: 767px) {
						font-size: 11px;
					}
				}

        @media screen and (max-width: 410px) {
          flex-direction: column;
          align-items: center;
        }
			}
		}

		.order-summary-data {
			font-size: 13px;
			font-weight: 400;
			color: #626262;

			.sub-fields-row {
				display: flex;
				padding: 1px 0;
				justify-content: space-between;
			}

			.sub-fields-val {
				font-weight: 700;
				color: #626262;
			}
		}

		.status-active {
			.card-header {
				border-left-color: #6cbf4a;

				.status {
					color: #6cbf4a
				}
			}

			.order-summary-data {
				.creditsEarned {
					color: #6cbf4a;
				}
			}
		}

		.status-passed {
			.card-header {
				border-left-color: #49b1b7;

				.status {
					color: #49b1b7
				}
			}

			.order-summary-data {
				.creditsEarned {
					color: #6cbf4a;
				}

				.totalPaid {
					color: #49b1b7
				}
			}

			.card-body {
				.sub-fields-value {
					color: #626262;
				}
			}
		}

		.status-cancelled {
			.card-header {
				// border-left-color: #de5c28;

				.status {
					color: #de5c28
				}
			}

			.card-body {
				.sub-fields-value {
					color: #626262;
				}
			}

			.order-summary-data {
				.creditsEarned {
					color: #6cbf4a;
				}

				.totalPaid {
					color: #49b1b7
				}
			}
		}
	}
}

.add-gift-banner,
.tickets-confirmed-banner {
	display: flex;
	height: 240px;
	align-content: center;
	justify-content: center;
	color: #fff;
	font-size: 32px;
	text-transform: uppercase;
	font-weight: 400;
	align-items: flex-end;
	padding-bottom: 50px;
	background-size: cover !important;

	@media(max-width:768px) {
		font-size: 28px;
	}
}

.add-gift-banner {
	background: url(../img/add_gift_page_banner.jpg) no-repeat center top;
}

.tickets-confirmed-banner {
	background: url(../img/header.png) no-repeat center top;
}

.addGiftWrapper {
	padding-bottom: 50px;
	padding-top: 50px;

	.header-content {
		padding: 50px 0;
		border-bottom: 0;
		margin-bottom: 0;

		.radioGroup {
			display: flex;
			justify-content: space-around;
			padding-top: 20px;

			label {
				display: block;
				background-color: #fff;
				width: 19%;
				font-weight: 600;
				border: #c0cdce 1px solid;
				border-radius: 2px;
				box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) inset;
				position: relative;
				overflow: hidden;
				cursor: pointer;

				@media(max-width:767px) {
					width: unset;
				}

				input {
					position: absolute;
					left: -100%;

					+.btn-checkmark {
						background-color: #fff;
						padding: 15px;
					}

					&:checked+.btn-checkmark {
						background-color: #3ab3b8;
						color: #fff;
					}
				}
			}
		}
	}

	.customAmountWrapper {
		padding-top: 50px;

		.input-group {
			span {
				position: absolute;
				top: 50%;
				left: 15px;
				transform: translateY(-50%);
				font-weight: 600;
				z-index: 20;
			}

			.form-control {
				padding-left: 40px;
			}
		}

		.totalAmount {
			font-size: 40px;
			font-weight: bold;
			color: #3ab3b8;
			line-height: 1;
		}
	}

	.card {
		margin-bottom: 35px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border: 0;

		.card-header {
			background-color: $black;
			text-align: center;
			color: #fff;
			padding: 18px 15px;

			h3 {
				margin: 0;
				font-weight: 400;
				font-size: 14px;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}

		p {
			font-weight: 300;
			font-size: 13px;
			color: #5f666a;
			line-height: 1.65;
		}
	}

	.form-label {
		text-transform: uppercase;
		color: $form-lable-color;
		font-size: 11px;
		padding-bottom: 10px;
		letter-spacing: 1px;
	}

	.form-control {
		padding: 10px 15px;
		border: #c0cdce 1px solid;
		border-radius: 2px;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) inset;
	}

	select.form-control {
		background: #fff url('../img/selec_arrow_down.png') no-repeat 96% center;
		-webkit-appearance: none;
		height: auto !important;
	}

	.react-datepicker__input-container input {
		background: $dark url(../img/calendar_icon.png) no-repeat right center;
	}
}

/*tickets-confirmed*/
.tickets-confirmed {
  max-width: 45rem;
  margin: 0 auto;
  .grid{
    display: grid;

    .download-btn{
      justify-self: end;
      padding: 1rem
    }
  }

  .ticketInfo {
    padding: 1rem;
    span{
      justify-self: end;
  }
}

.grid-stops{
    display: grid;
    grid-template-columns: auto auto 1fr;
}

  .otherDetails{
    background-color: #F9FAFB;
    padding: 1rem;
  }

  .sub-grid-depart{
    grid-template-columns: 10% 1fr;
    font-size: 14px;
    font-weight: 600;
    .title{
      color: #4E5864;
      font-weight: 700;
    }

    span{
      color: #4E5864;
      font-weight: 600;
    }

	span.cityDetail{
		color: #000000;
		padding: 0;
		@media screen and (max-width: 420px) {
			max-width: 6rem;
		}
	}
  }

  .sub-grid-depart-3{
    grid-template-columns: 10% 1fr auto;
    .time{
      grid-template-columns: 15% 15% 4% 15%;
      justify-items: left;
      align-items: center;
    }
  }

	.card {
    margin: 1rem;
		.card-header {
			background-color:  #252F3A;
			color: #fff;
		}

    .heading{
      background: #F9FAFB;
      padding: 16px;
      p{
        color: black;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }



		table {
			thead {
				tr {
					background-color: #f6fbff;

					td {
						border-bottom: 1px solid #dee2e6;
					}
				}
			}
		}

		.seatBook {
			font-size: 20px;
			color: #5d5d5d;
		}
	}

	.btn {
		margin-left: 10px;
		padding: 14px 15px 12px;
	}

	.btn-green {
		background-color: #4bb9bd;
	}

	.be-sure-join {
		padding-top: 50px;
		padding-bottom: 20px;
		font-size: 18px;

		.blue-text {
			color: $tripper2;
			font-weight: bold;
			text-decoration: underline
		}
	}

  @media screen and (max-width: 768px) {

    .sub-grid-depart{
      grid-template-columns: auto 1fr;
      padding: 3px;
      .title{
        padding-left: 6px;
      }
    }
     .sub-grid-depart-3{
        grid-template-columns: auto 1fr;

        .time{
          grid-template-columns: repeat(4, auto);
		  padding: 3px;
        }
    }

    .grid-stops{
      grid-template-columns: auto auto auto;
    }
  }

}

.mlr-0 {
	margin-left: 0;
	margin-right: 0;
}

.trip-row {
  // padding: 1rem;
	&.first-trip {
		border-top: 1px solid #f1f1f1;
	}

	span {
		padding-left: 5px;
		padding-right: 5px;
		font-weight: 300;

		&.bold {
			padding-left: 0;
			font-weight: 500;
		}

		// @media (max-width: 1200px) {
		// 	padding-left: 10px;
		// }
	}

	label {
		width: 100px;
		font-weight: 500;
	}

	.down-btn {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.res-container {
	padding-top: 30px;
}

.pos-rel {
	position: relative;
}

.select-trip-item .btn.disabled {
	cursor: not-allowed !important;
	background: #f1f1f1 !important;
  color: #000000 !important;
  border: #cfcfcf !important;
}

.not-enough {
	color: red !important;
}

.top-link {
	color: #ffffff !important;
}

.covid-wrap {
	width: 90%;
	max-width: 1000px;
	/* text-align: center; */
	margin: 0 auto;
	color: #F80A0A;
	border: 1px solid #F80A0A;
	padding: 20px 20px 5px;
	background-color: #FFF0F0;
	margin-top: 50px;
	.covid-head {
		font-weight: bold;
    margin-bottom: 5px;
	}
}

// .topAlertPadding {
// 	// padding-top: 40px;
// 	@media (max-width: 768px) {
// 		padding-top: 75px;
// 	}
// }

.elite-bus-icon {
	display: inline-block;
	text-align: center;
	background-color: $gray-900;
	color: $white;
	letter-spacing: .05rem;
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 20px;
	width: 40px;
	padding: 3px 0 4px;
	box-sizing: border-box;
    margin-left: 10px;
    margin-top: 0px;
}

.createAccount{

	& .checkbox{
	   padding-left: 0 !important;
	   padding-right: 0 !important;
   }
}


@media screen and (max-width: 767px){

  // .topAlertPadding{
  //   padding-top: 0;
  // }
  // #travel-form-region .content-full-width .tab-content, #travel-form-region .tab-pane{
  //   padding: 0;
  // }

  // #travel-form-region .chooseNoOfSeats select{
  //   background-position: 85% center;
  // }

  .mobileGrid{
    display: grid;
    grid-template-columns: auto auto;
  }

  .mobileForm{
    select{
      margin-bottom: 1rem !important;
    }
  }
}


#shadow-host-companion{
	display: none;
}


.openButton {
	background-color: #719c70;
	color: white;
	padding: 10px 15px;
	font-size: 20px;
	border-radius: 8px;
	border: none;
	cursor: pointer;
  }
  
  .openButton:hover {
	border: 1px solid black;
  }
  
  .drawer {
	display: flex;
	justify-content: center;
	width: 100%;
	color: white;
	font-size: 25px;
	padding-top: 20px;
  }
  
  //radio button
  .radioBtns{
    display: flex;
    align-items: center;
    gap: 16px;
	padding: .375rem 1.75rem .375rem 1rem;
}

.mylabel{
    display: flex;
    gap: 6px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
	margin: 0;
    line-height: normal;

}
.activeLabel{
    font-weight: 600!important;

}

.radioBorder{
    height: 20px;
    width: 20px;
    border: 1px solid #A5A5A5;
    border-radius: 15px;
    padding: 3px;
}
.radioBtn{
    width: 100%;
    height: 100%;
    border-radius: 15px;

    background-color: transparent;
}

.activeRadioBorder{
    border: 2px solid #E8512F!important;

}
.activeRadioBorder .radioBtn{
    background-color: #E8512F!important;
}


@media (max-width: 767px) {
	.radioBtns{
		padding: 0.375rem 0 1.2rem 1rem;
	}
	.mylabel{
		font-size: 12px;
	}
}
.return-row{
	display: flex;
	gap: 10px;
	.labelRD{
		margin: 0;
	}
}
.returnBtn{
    border-radius: 5px;
    border: none;
    font-family: inherit;
    display: flex;

    cursor: pointer; 
    font-size: 16px;
    align-items: center;
    color: white;
    font-size: 14px;
    background: transparent;
    font-style: normal;
    font-weight: 400;
    line-height: 0%;
    gap: 5px;
    transition: color 0.8s;
}
.circularBtn{
	padding: 3px;
	border-radius: 50%;
	background-color: white;
}
.returnBtn:hover{
    font-weight: 600;

    .circularBtn{
        transform: rotate(-270deg)
        

    }
    
}
.search{
	display: flex;
	justify-content: center;
}
.search > button{
    background-color: #E8512F;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    flex: 1;
    height: 39px;
	max-width: 300px;
    width: 100%;
    margin: 0;
    font-family: inherit;
    cursor: pointer; 
    font-size: 16px;
    color: white;
    transition: all 0.3s;
	text-align: center;
	@media (max-width:420px) {
		max-width: 100%;
	}
}
.search > button:enabled:hover{
    box-shadow: 0px 0px 20px 0px #E8512F;

}
.search > button:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.close-btn{
	margin-top: 28px;
	display: flex;
	justify-content: center;
	

}

.close-btn > button{
	background: transparent;
	cursor: pointer;
	border: none;
	color: #FFF;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	display: flex;
	align-items: center;
	font-weight: 600;
	line-height: normal;
	
}
.close-btn > button:disabled{
	opacity: 0.5;
	cursor: not-allowed;

}

.close-btn > button > p{
	border-bottom: 1px solid white;
	margin: 0;
}
//collapsed trip filter
.callapedLayout{
	display: flex;
	align-items: center;
	padding: 0 2px;
	justify-content: center;
	gap: 36px;
    transition: all 0.3s;


	@media (max-width:906px) {
		&{
			border-radius: 5px;
			border: 1px solid rgba(255, 255, 255, 0.20);
			flex-direction: column-reverse;
			gap: 0;
		}
		& > .details > img{
			display: none;
		}
	}
}
.details{
	display: flex;
	align-items: center;
	gap: 36px;
	@media (max-width:906px) {
		&{
			justify-content: space-between;
			width: 100%;
			padding: 10px 5px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.20);
		}
	}

}
.dateText{
	color: #FFF;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	@media (max-width:906px) {
		&{
			font-size: 12px;
		}
	}
}
.locations{
	display: flex;
	align-items: center;
	gap: 24px;
	@media (max-width:906px) {
		&{
			justify-content: space-between;
			width: 100%;
			padding: 10px 5px;
			gap: 0;

		}
	}
}
.location{
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.route{
	display: flex;
	text-transform: capitalize;
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	align-items: center;
	gap: 5px;
	line-height: normal;
	@media (max-width:906px) {
		&{
			font-size: 12px;
		}
		
	}
}
.callapedLayout  p{
	margin: 0;
}
.tripTypeText{
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	@media (max-width:906px) {
		&{
			font-size: 12px;
		}
		
	}
}

.editTripBtn{
	display: flex;
	justify-content: center;
}
.editTripBtn > button{
    background-color: #E8512F;
    border-radius: 5px;
    border: none;
	padding: 12px 17px;
    cursor: pointer; 
    font-size: 16px;
	text-transform: uppercase;

    color: white;
    transition: all 0.3s;
	text-align: center;
	@media (max-width:420px) {
		max-width: 100%;
	}
	@media (max-width:906px) {
		&{
			padding: 0;
			background-color: transparent;
			color:#E8512F;
			border-bottom: 1px solid #E8512F;
			border-radius: 0;
			margin: 0;
			font-size: 12px;



		}
	}
}
.editTripBtn > button:enabled:hover{
    box-shadow: 0px 0px 20px 0px #E8512F;

}
.editTripBtn > button:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.tab-narrow{
	padding-top: 20px!important;
	padding-bottom: 20px!important;
}